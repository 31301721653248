<template>
  <div class="container-fluid">
    <div class="page-title-box">
      <bread-crumb
        :breadcrumbs="[
          { label: 'Задачи', routeName: 'Task' },
          { label: 'Редактирование' },
        ]"
      />
      <h4 class="page-title">Редактирование</h4>
    </div>

    <div class="card">
      <div class="card-body">
        <Form
          :service="service"
          redirectAfterSave="TaskShow"
          :actions="[{ action: 'save', to: (data) => '/task/' + data.id }]"
        >
          <FormField
            label="Статус задачи"
            :service="service"
            field="status"
            type="select"
            :options="[
              { name: 'Не выполнена', id: 0 },
              { name: 'Выполняется', id: 1 },
              { name: 'Выполнена', id: 2 },
            ]"
          />
          <FormField
            label="Начало"
            :service="service"
            field="start"
            type="calendar"
          />
          <FormField
            label="Конец"
            :service="service"
            field="end"
            type="calendar"
          />
          <FormField
            label="Название"
            :service="service"
            field="name"
            type="text"
          />
          <FormField
            label="Постановщик"
            :service="service"
            field="producer"
            :options="userList"
            type="select"
          />
          <FormField label="Задание" :service="service" field="description" />
          <FormField
            label="Файл с заданием"
            :service="service"
            field="taskFile"
            :curFileName="service.taskFileName"
            type="file"
          />
          <FormField label="Отчёт" :service="service" field="reportText" />
          <FormField
            label="Файл с отчётом"
            :service="service"
            field="returnFile"
            :curFileName="service.returnFileName"
            type="file"
          />
          <FormField
            label="Ответственные"
            :service="service"
            field="employees"
            :options="userList"
            type="multiselect"
          />
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/ui/BreadCrumb.vue";
import Form from "@/components/ui/form/Form.vue";
import FormField from "@/components/ui/form/FormField.vue";
import Str from "@/utils/Str";
import TaskService from "../services/TaskService";
import UserService from "@/modules/user/services/UserService";

export default {
  components: {
    BreadCrumb,
    Form,
    FormField,
  },
  data() {
    return {
      service: new TaskService({
        expand: ["employeeList", "producerName"],
      }),
      userService: new UserService(),
      userList: {},
      Str: Str,
    };
  },
  async created() {
    await this.service.show(this.$route.params.id);
    this.userList = await this.userService.allList();
  },
  methods: {},
};
</script>
