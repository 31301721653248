import Service from '../../../services/Service';

class TaskService extends Service {

    basePath = 'task';

    newObject() {
        return new TaskService();
    }
    
}

export default TaskService;